:root {
  --main-accent-color: #8b3991;
  --background: white;
  --text-primary: black;
  --timeline-background: #dbbfdd;
  --highlight-color: #f0e5f1;
  --main-action-button: #8b3991;
}

[data-theme="dark"] {
  --main-accent-color: #8b3991;
  --background: #6e2e82;
  --text-primary: white;
  --timeline-background: #8b3991;
  --highlight-color: #dbbfdd;
  --main-action-button: #cabccb;
}

.App {
  text-align: center;
  background-color: var(--background);
  color: var(--text-primary);
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header-text {
  padding-bottom: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 2rem;
}

.header-subtitle {
  margin-top: 0;
  padding-top: 0;
  font-size: 1rem;
}

.dev-name {
  color: var(--main-accent-color);
  background: var(--highlight-color);
}

.action-button {
  border: 3px solid var(--text-primary);
  padding: 0.5em;
  color: var(--text-primary);
  font-weight: bold;
  text-decoration: none;
  margin-right: 1em;
}

.action-button:visited {
  border: 3px solid var(--text-primary);
  padding: 0.7em;
  color: var(--text-primary);
  text-decoration: none;
}

.main-action-button {
  border: 3px solid var(--main-action-button);
  color: var(--main-action-button);
  margin-right: 1em;
}

.main-action-button:visited {
  border: 3px solid var(--main-action-button);
  color: var(--main-action-button);
  margin-right: 1em;
}

.button-row {
  display: flex;
  justify-content: center;
}

.profile-image {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column-left {
  flex: 40%;
}

.column-right {
  flex: 60%;
}

@media screen and (max-width: 540px) {
  .column-left {
    flex: 100%;
  }

  .column-right {
    flex: 100%;
  }
}

.theme-button {
  padding-top: 1em;
  background-color: transparent;
  border: none;
  color: var(--text-primary);
}

.timeline {
  background-color: var(--timeline-background);
  padding: 1rem;
  min-height: 100vh;
}

.job-card {
  padding: 1em;
}

.company {
  text-decoration: underline;
}

.dates {
  font-style: italic;
}
